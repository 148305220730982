import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-hartford-connecticut.png'
import image0 from "../../images/cities/scale-model-of-ancient-burying-ground-in-hartford-connecticut.png"
import image1 from "../../images/cities/scale-model-of-connecticut's-old-state-house-in-hartford-connecticut.png"
import image2 from "../../images/cities/scale-model-of-harriet-beecher-stowe-center-in-hartford-connecticut.png"
import image3 from "../../images/cities/scale-model-of-bushnell-park-carousel-in-hartford-connecticut.png"
import image4 from "../../images/cities/scale-model-of-museum-of-connecticut-history-in-hartford-connecticut.png"
import image5 from "../../images/cities/scale-model-of-butler-mccook-house-&-garden-in-hartford-connecticut.png"
import image6 from "../../images/cities/scale-model-of-mark-twain-house-&-museum-in-hartford-connecticut.png"
import image7 from "../../images/cities/scale-model-of-keeney-memorial-cultural-center-in-hartford-connecticut.png"
import image8 from "../../images/cities/scale-model-of-connecticut-office-of-tourism-in-hartford-connecticut.png"
import image9 from "../../images/cities/scale-model-of-historical-commission-in-hartford-connecticut.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Hartford'
            state='Connecticut'
            image={image}
            lat='41.7658043'
            lon='-72.67337229999998'
            attractions={ [{"name": "Ancient Burying Ground", "vicinity": "60 Gold St, Hartford", "types": ["point_of_interest", "establishment"], "lat": 41.764968, "lng": -72.67463629999997}, {"name": "Connecticut's Old State House", "vicinity": "800 Main St, Hartford", "types": ["museum", "point_of_interest", "establishment"], "lat": 41.7660964, "lng": -72.67268030000002}, {"name": "Harriet Beecher Stowe Center", "vicinity": "77 Forest St, Hartford", "types": ["museum", "point_of_interest", "establishment"], "lat": 41.766822, "lng": -72.70037209999998}, {"name": "Bushnell Park Carousel", "vicinity": "1 Jewell St, Hartford", "types": ["amusement_park", "point_of_interest", "establishment"], "lat": 41.7651341, "lng": -72.67923559999997}, {"name": "Museum of Connecticut History", "vicinity": "231 Capitol Ave, Hartford", "types": ["museum", "point_of_interest", "establishment"], "lat": 41.7623164, "lng": -72.6831431}, {"name": "Butler-McCook House & Garden", "vicinity": "396 Main St, Hartford", "types": ["museum", "point_of_interest", "establishment"], "lat": 41.76066700000001, "lng": -72.67466769999999}, {"name": "Mark Twain House & Museum", "vicinity": "351 Farmington Ave, Hartford", "types": ["museum", "point_of_interest", "establishment"], "lat": 41.7670584, "lng": -72.70139670000003}, {"name": "Keeney Memorial Cultural Center", "vicinity": "200 Main St, Wethersfield", "types": ["museum", "point_of_interest", "establishment"], "lat": 41.7114403, "lng": -72.65267589999996}, {"name": "Connecticut Office of Tourism", "vicinity": "450 Columbus Blvd #5, Hartford", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 41.7677103, "lng": -72.66884140000002}, {"name": "Historical Commission", "vicinity": "The Gold Bldg, 755 Main St, Hartford", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 41.7676975, "lng": -72.67075649999998}] }
            attractionImages={ {"Ancient Burying Ground":image0,"Connecticut's Old State House":image1,"Harriet Beecher Stowe Center":image2,"Bushnell Park Carousel":image3,"Museum of Connecticut History":image4,"Butler-McCook House & Garden":image5,"Mark Twain House & Museum":image6,"Keeney Memorial Cultural Center":image7,"Connecticut Office of Tourism":image8,"Historical Commission":image9,} }
       />);
  }
}